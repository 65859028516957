<template>
  <v-app>
    <v-app-bar
      color="primary"
      dark
      max-height="100"
      flat
    >
    </v-app-bar>

    <v-main>

      <v-container>
        <v-row class="mt-12">
          <v-col class="col-3">
            <v-img
              max-height="300"
              max-width="300"
              src="./assets/logo.png"
            ></v-img>
          </v-col>
          <v-col class="col-9 d-flex justify-center">
            <div>
              <div class="text-h2 mb-6">Fiber for American Fork</div>
              <div class="text-h5">We're a grassroots effort working to get fiber internet for American Fork.</div>
              <div class="text-h5 mb-2">Join Us and Support</div>
              <v-btn
                depressed
                fab
                class="mb-6"
                color="primary"
                href="https://www.facebook.com/groups/fiberforamericanfork"
                target="_blank"
              >
                <v-icon dark large>
                  mdi-facebook
                </v-icon>
              </v-btn>
              <div class="text-h5">Check back soon for more information!</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer
      dark
      color="primary"
    >
      <v-card
        flat
        width="100%"
        color="primary"
        class="white--text text-center"
      >

        <v-card-text class="white--text pt-0">
          This site was created by McKay Butterfield, a resident in American Fork.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }}
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data: () => ({

  }),
};
</script>
